import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", [_c("select-filter", {
    key: "TruckCode",
    attrs: {
      "label": "Truck",
      "reference": "grower.common.trucks",
      "placeholder": "Select a Truck",
      "source": "code"
    }
  }), _c("select-filter", {
    key: "CropCode",
    attrs: {
      "label": "Crop",
      "reference": "grower.common.crops",
      "placeholder": "Select a Crop",
      "source": "code",
      "source-label": _vm.genCropCodeLabel
    }
  }), _c("select-filter", {
    key: "GrowerCode",
    attrs: {
      "label": "Grower",
      "reference": "grower.common.growers",
      "placeholder": "Select a Grower",
      "source": "code",
      "source-label": _vm.genGrowerLabel
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterOffsiteStorage",
  methods: {
    genCropCodeLabel(option) {
      return `${option.id} - ${option.name}`;
    },
    genGrowerLabel(option) {
      return `${option.id} - ${option.name}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterOffsiteStorage = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "grower.common.trucks",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.growers",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.offsite-storages",
      "api-url": _vm.apiUrl,
      "redirect-route": "/watties-grower/offsite-storage"
    }
  }, [_c("filter-offsite-storage")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    FilterOffsiteStorage
  },
  data() {
    return {
      FilterOffsiteStorage,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
